import { render, staticRenderFns } from "./rongYuZz.vue?vue&type=template&id=5bf0f4aa&scoped=true&"
import script from "./rongYuZz.vue?vue&type=script&lang=js&"
export * from "./rongYuZz.vue?vue&type=script&lang=js&"
import style0 from "./rongYuZz.vue?vue&type=style&index=0&id=5bf0f4aa&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bf0f4aa",
  null
  
)

export default component.exports